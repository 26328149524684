import axios from "@/axios/axiosConfig";

export const queryCustomerListBySaleId = (params) => {
  return axios({
    url: `/allocation/queryCustomerListBySaleId/${params.saleId}`,
    method: "get"
  });
};

export const doAllocation = (params) => {
  return axios({
    url: "/allocation/do-allocation",
    method: "post",
    data: params
  });
};
