<template>
  <div class="app-box">
    <x-scroll-view :bottom="0.66">
      <x-form-box>
        <x-form-input label="当前维护人" disable v-model="name"></x-form-input>
        <x-form-input label="变更后维护人" disable v-model="changeName">
          <i class="name-tag-icon" @click="namTagEvent"></i>
        </x-form-input>
      </x-form-box>
    </x-scroll-view>
    <x-footer-box type="new" :isDisabledBtn="isDisabledBtn" @saveEvent="saveClick"></x-footer-box>
  </div>
</template>

<script>
import formBox from "@/components/formControl/formBox";
import formInput from "@/components/formControl/formInput";
import footerBox from "@/components/footerBox";
import scrollView from "@/components/scrollView";
import { cachePage } from "@/script/mixins/cachePage";
import { ajaxBack } from "@/script/mixins/ajaxBack";
import { doAllocation } from "@/api/customerAssignment";

export default {
  name: "customerAssignmentBatchMaintain",
  data () {
    return {
      isDisabledBtn: true,
      name: this.$route.params.name,
      pageBack: parseInt(this.$route.params.pageBack),
      changeName: null,
      saleId: null
    };
  },
  mixins: [cachePage, ajaxBack],
  created () {
    document.title = "";
    document.title = "编辑维护人";
  },
  methods: {
    namTagEvent () {
      this.$router.push({
        path: "/searchCustomerAssignment/assignment"
      });
    },
    saveClick () {
      if (!this.isDisabledBtn) {
        doAllocation({
          customerIdList: this.$route.params.idList.split(","),
          saleId: this.saleId
        }).then(xhr => {
          this.ajaxBack(xhr, "操作成功！", -this.pageBack);
        });
      }
    }
  },
  watch: {
    $route () {
      const searchValue = this.$store.getters.getSearchValue;
      if (searchValue) {
        this.saleId = searchValue.id;
        this.changeName = searchValue.name;
        this.isDisabledBtn = false;
        this.$store.commit("setSearchValue", null);
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [formInput.name]: formInput,
    [formBox.name]: formBox,
    [footerBox.name]: footerBox
  }
};
</script>

<style scoped>
.pos-address-icon {
  margin-left: 0.1rem;
  width: 0.14rem;
  height: 0.18rem;
  background: url('~@images/pos_address_icon.png') no-repeat center/0.14rem
    0.18rem;
}
.name-tag-icon {
  margin-left: 0.1rem;
  width: 0.15rem;
  height: 0.18rem;
  background: url('~@images/name_tag_icon.png') no-repeat center/0.15rem 0.18rem;
}
</style>
